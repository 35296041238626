import React, { useEffect } from "react";

import {
    SEO,
} from "@components";


export default function LojaPage() {
    useEffect(() => {
        window.location.replace("https://www.loja.reactconf.com.br/MLB-2895371232-camiseta-_JM");
    });

    return (
        <main>
            <SEO title="Loja" />
        </main>
    );
}

